/** @jsx jsx **/
// import { ErrorBoundary } from '@sentry/react'
import { FC, Fragment } from 'react'
import { Helmet } from 'react-helmet'
import {
  jsx,
  Box,
  ThemeUIProvider,
  Theme,
  Divider,
  ThemeUICSSObject,
  ThemeUIStyleObject,
} from 'theme-ui'

import { HeaderVariantType } from '~/@types/models'
import { env } from '~/common/env'
import CollectionDescription from '~/components/collection-description'
import Footer from '~/components/footer'
import Header from '~/components/header'
import TopBanner from '~/components/header/top-banner'
import Overlay from '~/components/overlay'
import SEO from '~/components/seo'
import theme from '~/gatsby-plugin-theme-ui'
import { BespokeFooter } from '../footer/bespoke-footer'
import { BespokeHeader } from '../header/bespoke-header'

export interface LayoutProps {
  headerVariant?: HeaderVariantType
  headerSxProps?: ThemeUIStyleObject
  image?: string
  footer?: boolean
  topBanner?: boolean
  title?: string
  seoDescription?: string
  seoTitle?: string
  description?: string
  stickyMenu?: boolean
  className?: string
  sx?: ThemeUICSSObject
  isCollection?: boolean
  ankleContent?: string
  noIndex?: boolean
  bodySx?: ThemeUICSSObject
  template?: string
}

const Layout: FC<LayoutProps> = ({
  children,
  headerVariant = 'light',
  headerSxProps,
  footer = true,
  title = '',
  seoDescription = '',
  description = '',
  image = null,
  topBanner = true,
  stickyMenu = false,
  className,
  isCollection = false,
  ankleContent = '',
  seoTitle = '',
  noIndex = false,
  sx,
  bodySx,
  template,
}) => {
  const collectionDescription = isCollection ? ankleContent || description : ankleContent
  const seoTitleToRender = seoTitle?.length > 0 ? seoTitle : title
  const isBespoke = template === 'bespoke'
  const HeaderComponent = isBespoke ? BespokeHeader : Header
  const FooterComponent = isBespoke ? BespokeFooter : Footer

  return (
    <div id="layout-container" data-theme={template}>
      <ThemeUIProvider theme={theme as Theme}>
        <Helmet>
          <link rel="apple-touch-icon" sizes="180x180" href="/fav/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/fav/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/fav/favicon-16x16.png" />
          <link rel="mask-icon" href="/fav/safari-pinned-tab.svg" color="#5bbad5" />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff" />
          <meta name="google-site-verification" content={env.googleSiteVerification} />
          <meta
            name="google-site-verification"
            content="5tLuptWhIVFVGT0jZX0bgfJOU5aq_2L6GyjwX0FnQhk"
          />

          <link rel="preconnect" href={process.env.GATSBY_ASSETS_CDN} />
          <link rel="dns-prefetch" href={process.env.GATSBY_ASSETS_CDN} />
          {!noIndex && <meta name="robots" content="index,follow" />}
        </Helmet>
        <SEO title={seoTitleToRender} description={seoDescription || description} image={image} />
        {topBanner && !isBespoke ? <TopBanner /> : null}
        <Box
          sx={{
            ...headerSxProps,
            display: 'contents',
          }}
        >
          <HeaderComponent variant={headerVariant || 'light'} stickyMenu={stickyMenu} />
        </Box>
        <Box
          id="page-layout-main-bsol"
          as="main"
          className={className}
          sx={
            !isBespoke
              ? {
                  ...bodySx,
                  ...(headerVariant === 'default' && {
                    marginTop: theme.menu.header.height,
                    position: 'relative',
                    minHeight: '60vh',
                  }),
                  ...sx,
                }
              : {
                  ...bodySx,
                  ...sx,
                  '.simple-content__title': {
                    fontFamily: 'var(--font-family-sans-serif)',
                    fontSize: '18.6px',
                  },
                }
          }
        >
          {children}
          {/* <ErrorBoundary fallback="Something went wrong.">{children}</ErrorBoundary> */}
        </Box>

        {collectionDescription && (
          <Box sx={{ position: 'relative', zIndex: 1000, background: 'white' }}>
            <Divider />
            <CollectionDescription description={collectionDescription} />
          </Box>
        )}
        {footer && <FooterComponent />}
        <Overlay />
      </ThemeUIProvider>
    </div>
  ) as JSX.Element
}

export default Layout
