import { useMemo } from 'react'
import {
  Maybe,
  ProductDetailPageQuery,
  ProductVariantType,
  ShopifyImageSource,
} from '~/@types/models'
import { findRunnersOrCircularImages, isException, isFabric } from '../../utils'
import { isRugProduct, overheadType } from '~/utils/product'
import useLinkState from '~/hooks/use-link-state'
import { ProductViewSliderItem } from '../../product-detail-view'

interface UseProductSlidersProps {
  product: ProductDetailPageQuery['product']
  productVariant: ProductVariantType
}

export const useProductSliders = ({
  product,
  productVariant,
}: UseProductSlidersProps): ProductViewSliderItem[] => {
  const { comesFromRunners, comesFromCircular } = useLinkState()

  return useMemo(() => {
    const sku = productVariant.sku
    const imagesBySku = product.imagesBySKU.find(group => group.sku === sku)
    let images: ShopifyImageSource[] = []
    // business exception
    if (isFabric(product)) {
      // @ts-ignore
      images = imagesBySku.images
    } else if (isException(product)) {
      // business exception
      images = product.images
    } else if (imagesBySku && imagesBySku.images.length > 0) {
      images = imagesBySku.images as ShopifyImageSource[]
    } else {
      images = product.images
    }

    // business exception - if presents a overhead rugs image then put it first
    if (isRugProduct(product)) {
      const imagesWithoutOH = images.filter(
        (item: any) => item.originalSrc.indexOf(overheadType) < 0
      )
      const imagesOH: any = images.filter(
        (item: any) => item.originalSrc.indexOf(overheadType) > -1
      )

      images = [...imagesOH, ...imagesWithoutOH]
    }

    // business exception - if presents a runners and circular rugs image then put it first
    if (product.isRunnerOrCircularRug) {
      const runnerOrCircular = findRunnersOrCircularImages(
        product,
        productVariant,
        comesFromRunners,
        comesFromCircular
      )
      if (runnerOrCircular) {
        const imageId = runnerOrCircular.id
        images = images.filter(image => image.id !== imageId)
        images = [runnerOrCircular, ...images]
      }
    }

    const items: ProductViewSliderItem[] = images.map((image, index) => ({
      title: `${product.title ? product.title : 'product'} - thumbnail ${index + 1}`,
      type: 'image',
      alt: image.altText,
      thumb: image.srcSmall,
      image: image.srcLargeDesktop,
    }))

    const videoId = !!productVariant?.metafields.videoId
      ? productVariant.metafields.videoId
      : product?.metafields.productVideo

    if (videoId) {
      items.splice(2, 0, {
        title: 'product video',
        type: 'video',
        videoId,
      })
    }
    return items.filter(item => typeof item.image !== 'undefined')
  }, [productVariant.id, product.id])
}
